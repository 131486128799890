import { LOGIN_SUCCESS, LOGIN_FAILURE, LOGOUT,LOGIN_REQUEST } from '../types';

const initialState = {
  isAuthenticated: false,
  user: null,
  loading: false,
  error: null, // Initialize error here
};
  export default function (state = initialState, action) {
      console.warn('action',action);
      switch (action.type) {
        case LOGIN_SUCCESS:
          return {
            ...state,
            isAuthenticated: true,
            user: action.payload,
            loading: false,
            error: null, // Reset error on success
          };
        case LOGIN_REQUEST:
          return {
            ...state,
            loading: true,  // Set loading to true on login request
            error: null,    // Reset error on new request
          };
        case LOGIN_FAILURE:
          return {
            ...state,
            isAuthenticated: false,
            user: null,
            loading: false,
            error: action.payload, // Set error on failure
          };
        case LOGOUT:
          return {
            ...state,
            isAuthenticated: false,
            user: null,
            error: null, // Reset error on logout
          };
        default:
          return state;
      }
  }