export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAILURE = 'LOGIN_FAILURE';
export const LOGIN_REQUEST = 'LOGIN_REQUEST';
export const LOGOUT = 'LOGOUT';
export const SET_LOADING = 'SET_LOADING';

export const SET_MESSAGE = "SET_MESSAGE";
export const CLEAR_MESSAGE = "CLEAR_MESSAGE";

export const SUCCESS_CONFIGDATA= "SUCCESS_CONFIGDATA";
export const CONFIGFORM_FAILURE= "CONFIGFORM_FAILURE";
