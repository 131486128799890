import { configureStore } from '@reduxjs/toolkit';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import rootReducer from './reducers'; // Import your root reducer

// Configuration for redux-persist
const persistConfig = {
  key: 'persist-key',
  storage,
};

// Wrap the root reducer with persistReducer
const persistedReducer = persistReducer(persistConfig, rootReducer);

// Create the store with configureStore
const store = configureStore({
  reducer: persistedReducer,
  devTools: process.env.NODE_ENV !== 'production', // Enable Redux DevTools in development mode
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        // Ignore these action types from the serializability check
        ignoredActions: ['persist/PERSIST', 'persist/REHYDRATE', 'persist/FLUSH', 'persist/PAUSE', 'persist/REMOVE', 'persist/UPDATE','persist/PURGE'],
        // Ignore these paths from the serializability check
        ignoredPaths: ['persist.persistor'],
      },
    }),
});

// Create the persistor
const persistor = persistStore(store);

export default store;
export { persistor };
