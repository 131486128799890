import {SET_LOADING } from "../types";

const initialState = {loadingBar:0};

export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {

    case SET_LOADING:
      return { ...state,loadingBar : payload!=null?payload:state.loadingBar };

    default:
      return state;
  }
}