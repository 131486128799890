import React from "react";
import { Link } from "react-router-dom";
import { ErrorMessage } from "@hookform/error-message";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import {addProduct} from '../../redux/actions/product';
import { TOASTERROR, TOASTSUCCESS } from "../../config/constant";
import toastalert from "../../components/Toast/toastalert";

function AddProduct() {
  const dispatch = useDispatch();
  const { message } = useSelector((state) => state.message);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ criteriaMode: "all" });

  const onSubmit = (data) => {
    console.log("addprduct data", data);
    dispatch(addProduct(data)).then((response)=>{
      toastalert(message,TOASTSUCCESS);
    }).catch(()=>{
      toastalert(message,TOASTERROR);
    });
  };
  return (
    <div className="content-wrapper">
      {/* Content Header (Page header) */}
      <section className="content-header">
        <div className="container-fluid">
          <div className="row mb-2">
            <div className="col-sm-6">
              <h1>Add Product</h1>
            </div>
            <div className="col-sm-6">
              <ol className="breadcrumb float-sm-right">
                <li className="breadcrumb-item">
                  <Link to="/">Home</Link>
                </li>
                <li className="breadcrumb-item active">Add Product</li>
              </ol>
            </div>
          </div>
        </div>
        {/* /.container-fluid */}
      </section>
      {/* Main content */}
      <section className="content">
        <div className="container-fluid">
          <div className="row">
            {/* left column */}
            <div className="col-md-12 card card-primary">
              <form onSubmit={handleSubmit(onSubmit)}>
                <div className="card-body">
                  <div className="col-md-5 form-group">
                    <label htmlFor="inputName">Project Name</label>
                    <input
                      type="text"
                      id="inputName"
                      className={`form-control ${
                        errors.title ? "is-invalid" : ""
                      }`}
                      {...register("title", {
                        required: "Enter Product Title",
                        minLength: {
                          value: 2,
                          message: "Title must have exceed 2 character",
                        },
                      })}
                    />
                    <ErrorMessage
                      errors={errors}
                      name="title"
                      render={({ messages }) => {
                        return messages
                          ? Object.entries(messages).map(([type, message]) => (
                              <div className="invalid-feedback" key={type}>
                                {message}
                              </div>
                            ))
                          : null;
                      }}
                    />
                  </div>
                  <div className="col-md-5 form-group">
                    <label htmlFor="inputDescription">
                      Project Description
                    </label>
                    <textarea
                      id="inputDescription"
                      className={`form-control ${
                        errors.description ? "is-invalid" : ""
                      }`}
                      rows={4}
                      defaultValue={
                        "Raw denim you probably haven't heard of them jean shorts Austin. Nesciunt tofu stumptown aliqua butcher retro keffiyeh dreamcatcher synth. Cosby sweater eu banh mi, qui irure terr."
                      }
                      {...register("description", {
                        required: "Enter Product desc",
                      })}
                    />
                    {errors.description && (
                      <div className="invalid-feedback">
                        {errors.description.message}
                      </div>
                    )}
                  </div>
                  <div className="col-md-5 form-group">
                    <label htmlFor="inputStatus">Status</label>
                    <select
                      id="inputStatus"
                      className="form-control custom-select"
                      defaultValue={"Success"}
                    >
                      <option disabled>Select one</option>
                      <option value="On Hold">On Hold</option>
                      <option value="Canceled">Canceled</option>
                      <option value="Success">Success</option>
                    </select>
                  </div>
                  <div className="col-md-5 form-group">
                    <label htmlFor="inputClientCompany">Client Company</label>
                    <input
                      type="text"
                      id="inputClientCompany"
                      className="form-control"
                      defaultValue="Deveint Inc"
                    />
                  </div>
                  <div className="col-md-5 form-group">
                    <label htmlFor="inputProjectLeader">Project Leader</label>
                    <input
                      type="text"
                      id="inputProjectLeader"
                      className="form-control"
                      defaultValue="Tony Chicken"
                    />
                  </div>
                </div>
                {/* /.card-body */}
                <div className="card-footer">
                  <button type="submit" className="btn btn-primary">
                    Submit
                  </button>
                </div>
              </form>
            </div>
          </div>
          {/* /.row */}
        </div>
        {/* /.container-fluid */}
      </section>
      {/* /.content */}
    </div>
  );
}

export default AddProduct;
