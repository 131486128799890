import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useParams } from 'react-router-dom';
import ApiService from '../../services/apiService'; // Adjust the import path as needed
import { createValidationSchema, createFormFields } from '../../common/formUtils';
import { configform } from "../../redux/actions/configform";

const DynamicForm = () => {
  //const [formType, setformType] = useState('');

  const { formType, id } = useParams(); // Capture the form name and ID from the URL
  const formId = id ? id : 0;
  const [fields, setFields] = useState([]);
  const [form, setForm] = useState({});
  const [defaultValues, setDefaultValues] = useState({});
  //const {error,form } = useSelector((state) => state.configform);
  // const dispatch = useDispatch();
  // useEffect(() => {
  //   dispatch(configform({formType, id: formId}));
    
  //   //const selectedForm = formsData[formType];
  //   if (form) {
  //     setFields(form.fields);
  //     const values = form.fields.reduce((acc, field) => {
  //       acc[field.name] = field.defaultValue || '';
  //       return acc;
  //     }, {});
  //     setDefaultValues(values);
     
  //   } else {
  //     console.error(`Form type '${formType}' not found in formsData.json`);
  //   }
  // }, [formType, formId]);
  const [error, setError] = useState(null);
  useEffect(() => {
    const fetchFormData = async () => {
      try {
        const form = await ApiService.post(`/configform`, { formType, id: formId });
        setFields(form.data.fields);
        setForm(form.data);
        const values = form.data.fields.reduce((acc, field) => {
          acc[field.name] = field.defaultValue || '';
          return acc;
        }, {});
        setDefaultValues(values);
      } catch (err) {
        setError(err.message || 'Error fetching form data');
      }
    };

    fetchFormData();
  }, [formType, formId]);

  const validationSchema = createValidationSchema(fields);

  const { handleSubmit, register, setValue, formState: { errors }, reset, watch } = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: defaultValues,
  });

  useEffect(() => {
    reset(defaultValues);
  }, [defaultValues, reset]);

  const onSubmit = (data) => {
    console.log('Form Values:', data); 

    const formData = new FormData();
    fields.forEach(field => {
      if (field.type === 'file') {
        if (field.validation?.required && (!data[field.name] || data[field.name].length === 0)) {
          alert("File required to submit form");
          return;
        } else {
          data[field.name].forEach(file => formData.append(field.name, file));
        }
      } else {
        formData.append(field.name, data[field.name]);
      }
    });

    ApiService.post(`/api/${formType}`, formData)
      .then(() => {
        alert('Form submitted successfully!');
      })
      .catch((error) => {
        console.error('Error submitting form:', error);
        alert('Error submitting form');
      });
  };
  return (
    <div className="content-wrapper">
      <section className="content-header">
        <div className="container-fluid">
          <div className="row mb-2">
            <div className="col-sm-6">
              {/* <h1>{formsData[formType]?.formTitle || 'Form'}</h1> */}
            </div>
            <div className="col-sm-6">
              <ol className="breadcrumb float-sm-right">
                <li className="breadcrumb-item">
                  <a href="#">Home</a>
                </li>
                <li className="breadcrumb-item active">{formType || 'Form'}</li>
              </ol>
            </div>
          </div>
        </div>
      </section>
      <section className="content">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-12">
              <div className="card card-primary">
                <div className="card-header">
                  <h3 className="card-title">{form?.formTitle || 'Form'}</h3>
                </div>
                {error && (
              <div className="form-group">
                <div className="alert alert-danger" role="alert">
                  {error}
                </div>
              </div>
            )}
                <form onSubmit={handleSubmit(onSubmit)}>
                  <div className="card-body">
                    {createFormFields(fields, register, errors, setValue, defaultValues)}
                  </div>
                  <div className="card-footer">
                    <button type="submit" className="btn btn-primary">
                      Submit
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default DynamicForm;
