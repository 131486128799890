import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';

const FileUpload = ({ field, register, setValue, errors, existingFiles = [] }) => {
  const [files, setFiles] = useState([]);
  const [uploading, setUploading] = useState(false);
  const [progress, setProgress] = useState({});
  const [currentUploads, setCurrentUploads] = useState([...existingFiles]);
  
  const fileInputRef = useRef(null); // Reference to file input for resetting

  useEffect(() => {
    setCurrentUploads(existingFiles);
  }, [existingFiles]);

  const handleFileChange = async (e) => {
    const selectedFiles = Array.from(e.target.files);

    const validFiles = selectedFiles.filter(file => {
      const maxSize = field.validation?.size || 2 * 1024 * 1024; // Default 2MB
      const isValidSize = file.size <= maxSize;
      const isValidFormat = (field.validation?.formats || ['image/jpeg', 'image/png']).includes(file.type);
      if (!isValidSize) {
        alert(`File ${file.name} size must be less than ${(maxSize / (1024 * 1024))}MB`);
      }
      if (!isValidFormat) {
        alert(`File ${file.name} format is not supported`);
      }
      return isValidSize && isValidFormat;
    });

    if (validFiles.length > 0) {
      setUploading(true);
      setFiles(validFiles);

      // Map files to upload
      const uploaded = await Promise.all(validFiles.map(file => uploadFile(file)));
      setUploading(false);

       // Filter out null results
       const validUploaded = uploaded.filter(file => file !== null);
      // Update current uploads with new files
      setCurrentUploads(prev => [...prev, ...validUploaded]);
      setValue(field.name, [...currentUploads, ...validUploaded]);

      // Clear file input value
      if (fileInputRef.current) {
        fileInputRef.current.value = '';
      }
    }
  };

  const uploadFile = async (file) => {
    const formData = new FormData();
    formData.append('file', file);
    try {
      const { data } = await axios.post('http://localhost:5000/upload', formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
        onUploadProgress: (progressEvent) => {
          const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
          setProgress(prev => ({ ...prev, [file.name]: percentCompleted }));
        }
      });

      // Clean up progress state for the uploaded file
      // setProgress(prev => {
      //   const updatedProgress = { ...prev };
      //   delete updatedProgress[file.name];
      //   return updatedProgress;
      // });
      

      return { fileName: file.name, fileUrl: data.fileUrl };
    } catch (error) {
      console.error('Upload failed:', error);
      alert(error.message)
      return null;
    }
  };

  const handleDelete = (fileUrl,e) => {
    e.preventDefault(); // Prevent the default action
  e.stopPropagation(); // Stop the event from propagating
    const updatedUploads = currentUploads.filter(url => url !== fileUrl);
    setCurrentUploads(updatedUploads);
    setValue(field.name, updatedUploads);
  };

  return (
    <div className="form-group">
      <label htmlFor={field.name}>{field.label}</label>
      <input
        id={field.name}
        type="file"
        className="form-control-file"
        multiple
        onChange={handleFileChange}
        accept={field.accept}
        disabled={uploading}
        ref={fileInputRef} // Attach the ref to the file input
      />
      {errors[field.name] && <small className="form-text text-danger">{errors[field.name]?.message}</small>}
      
      {files.length > 0 && (
        <div>
          {files.map(file => (
            <div key={file.name} className="file-item">
              <span>{file.name}</span>
              <div className="progress mt-2">
                <div
                  className="progress-bar"
                  role="progressbar"
                  style={{ width: `${progress[file.name] || 0}%`, backgroundColor: '#007bff' }} // Set fill color directly if needed
                  aria-valuenow={progress[file.name] || 0}
                  aria-valuemin="0"
                  aria-valuemax="100"
                >
                  {progress[file.name]}%
                </div>
              </div>
            </div>
          ))}
        </div>
      )}
      
      {currentUploads.length > 0 && (
        <div className="image-grid mt-2">
          {currentUploads?.map((i, index) => (
            <div key={index} className="image-grid-item">
              <img src={i.fileUrl} alt={`Uploaded ${index}`} className="thumbnail" />
              <button
                className="btn btn-sm btn-danger delete-button"
                onClick={(e) => { e.stopPropagation(); handleDelete(i,e); }}
              >
                <i className="bi bi-x"></i>
              </button>
            </div>
          ))}
        </div>
      )}
      {currentUploads.length === 0 && (
        <p>No Files uploaded. Submit form without files</p>
      )}
    </div>
  );
};

export default FileUpload;
