// src/pages/PageNotFound.js
import React from 'react';

const PageNotFound = () => {
  return (
    <div>
      <h1>404 - Page Not Found</h1>
    </div>
  );
};

export default PageNotFound;
