import {ADD_PRODUCT,LOAD_PRODUCT,SET_MESSAGE} from '../types';
import { TOASTSUCCESS,
    TOASTWARNING,
    TOASTERROR,
    TOASTTIMEOUT} from '../../config/constant';
import ProductService from '../../services/product-service';
import { clearMessage, setMessage } from "./message";
import { setLoading } from "./loading";
import toastalert from '../../components/Toast/toastalert';

export const addProduct=(data)=>(dispatch)=>{
    dispatch(setLoading(30));
    dispatch(setLoading(70));
    return ProductService.addProduct(data).then((data)=>{
        if(data.status){
            console.log('data',data);
            dispatch(setLoading(100));
           // toastalert("Data Add Successfully",TOASTSUCCESS);
          // toastalert("Data Add Successfully",TOASTSUCCESS);
            dispatch(setMessage("Data Add Successfully"));
            
        }
       
        return Promise.resolve();
    },
    (error)=>{
        dispatch(setLoading(0));
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
          console.log("message",message);
          dispatch({
            type: SET_MESSAGE,
            payload: message,
          });
        return Promise.reject();
    }
    )
}