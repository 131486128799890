// apiService.js
import axios from 'axios';
import { getToken } from '../common/getToken';


// Set your base URL here
const BASE_URL = 'http://localhost:81/PHPCoreApi';

// Create an Axios instance
const api = axios.create({
  baseURL: BASE_URL,
  timeout: 10000, // Set a timeout for requests
  headers: {
    'Content-Type': 'application/json',
  },
});

// Request Interceptor
api.interceptors.request.use(
  config => {
    const token = getToken(); // Get the token from the Redux store

    // Attach the token to the headers if it exists and not a login request
    if (token && config.url !== '/auth/login') {
      config.headers['Authorization'] = `Bearer ${token}`;
    }

    return config;
  },
  error => {
    return Promise.reject(error);
  }
);

// Response Interceptor
api.interceptors.response.use(
  response => response.data, // Directly return the data from the response
  error => {
    // Customize error handling
    const errorMsg = error.response?.data?.message || error.message || 'An error occurred';
    return Promise.reject(new Error(errorMsg));
  }
);

// Generic API Service
const ApiService = {
  get: async (url, params) => {
    try {
      const response = await api.get(url, { params });
      return response;
    } catch (error) {
      throw error; // Handle or rethrow the error as needed
    }
  },
  post: async (url, data) => {
    try {
      const response = await api.post(url, data);
      return response;
    } catch (error) {
      throw error; // Handle or rethrow the error as needed
    }
  },
  put: async (url, data) => {
    try {
      const response = await api.put(url, data);
      return response;
    } catch (error) {
      throw error; // Handle or rethrow the error as needed
    }
  },
  delete: async (url) => {
    try {
      const response = await api.delete(url);
      return response;
    } catch (error) {
      throw error; // Handle or rethrow the error as needed
    }
  },
};

export default ApiService;
