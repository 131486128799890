import React from "react";
import { BrowserRouter, Routes, Route, useNavigate } from "react-router-dom";
import Footer from "../common/footer";
import Header from "../common/header";
import Sidebar from "../common/sidebar";
import Forgotpass from "../components/Auth/forgotpass";
import Login from "../components/Auth/login";
import Recoverpass from "../components/Auth/recoverpass";
import Forms from "../pages/Forms/forms";
import Home from "../pages/Home/home";
import Profile from "../pages/Profile/profile";
import Tables from "../pages/Tables/tables";
import { useSelector } from "react-redux";
import AddProduct from "../pages/Product/addproduct";
import LoadingBar from "react-top-loading-bar";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ViewProduct from "../pages/Product/viewproduct";
import DynamicForm from "../pages/Forms/DynamicForm";
import PageNotFound from "../pages/PageNotFound";

function RequireAuth({ children }) {
    const Navigate = useNavigate();
    const { isAuthenticated } = useSelector((state) => state.auth);

    if (!isAuthenticated) {
        return <Navigate to="/login" />;
    }

    return children;
}

function AppRoute() {
    const { isAuthenticated } = useSelector((state) => state.auth);
    const { loadingBar } = useSelector((state) => state.loading);

    return (
        <>
            <BrowserRouter>
                <ToastContainer />
                <LoadingBar color="#005abb" height={3} progress={loadingBar} />
                {isAuthenticated && (
                    <>
                        <Header />
                        <Sidebar />
                    </>
                )}

                <Routes>
                    {/* Normal routing */}
                    <Route exact path="/" element={isAuthenticated ? <Home /> : <Login />} />
                    <Route exact path="/home" element={isAuthenticated ? <Home /> : <Login />} />
                    <Route exact path="/adddata" element={isAuthenticated ? <Forms /> : <Login />} />
                    {/* <Route exact path="/viewdata" element={isAuthenticated ? <Tables /> : <Login />} /> */}
                    <Route exact path="/profile" element={isAuthenticated ? <Profile /> : <Login />} />
                    <Route exact path="/addproduct" element={isAuthenticated ? <AddProduct /> : <Login />} />
                    <Route exact path="/viewproduct" element={isAuthenticated ? <ViewProduct /> : <Login />} />
                    <Route exact path="/add/:formType/:id?" element={isAuthenticated ? <DynamicForm /> : <Login />} />
                    <Route exact path="/view/:formType" element={isAuthenticated ? <Tables /> : <Login />} />
                
                    {/* Auth routing */}
                    <Route exact path="/login" element={<Login />} />
                    <Route exact path="/forgot-pass" element={<Forgotpass />} />
                    <Route exact path="/recover-pass" element={<Recoverpass />} />
                    {/*No page found*/}
                    <Route path="/page-not-found" element={<PageNotFound />} />
                </Routes>

                {isAuthenticated && <Footer />}
            </BrowserRouter>
        </>
    );
}

export default AppRoute;
