import React, { useState, useEffect, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import DataTable from '../../common/DataTable';
import ApiService from '../../services/apiService';
import processValue from '../../common/processValue';

// Function to generate columns from data keys
const generateColumns = (data) => {
  if (!data.length) return [];

  const excludedKeys = ['appdataid', 'appdatastatus']; // Keys to exclude
  const keys = Object.keys(data[0]).filter(key => !excludedKeys.includes(key)); // Filter out excluded keys

  const columns = keys.map(key => ({
    Header: key.charAt(0).toUpperCase() + key.slice(1), // Capitalize column headers
    accessor: key,
    Cell: ({ value }) => processValue(value) // Call processValue to handle rendering the value
  }));

  // Add custom Actions column
  columns.push({
    Header: 'Actions',
    accessor: 'edit', // Custom accessor for actions column
  });

  return columns;
};

function Tables() {
  const { formType } = useParams(); // Get type from URL params
  const [data, setData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const form = await ApiService.post(`/getAppData`, { formType, id: 0 });
        if (form.status === 'ok') {
          setData(form.data);  // Set data directly
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, [formType]);


  // Function to handle making an image primary
  const onMakePrimary = (appDataId, imageId) => {
    setData(prevData =>
      prevData.map(row => {
        if (row.id === appDataId) { // Match the appDataId
          return {
            ...row,
            images: row.images.map(image =>
              image.id === imageId
                ? { ...image, primaryStatus: 1 } // Set this image as primary
                : { ...image, primaryStatus: 0 } // Set others as non-primary
            ),
          };
        }
        return row;
      })
    );
  };

  // Dynamically generate columns based on data
  const columns = useMemo(
    () =>
      generateColumns(data).map((col) => ({
        ...col,
        Cell: ({ value, row }) => processValue(value, (imageId) => onMakePrimary(row.id, imageId)), // Pass appDataId and imageId
      })),
    [data]
  );
  return (
    <div className="content-wrapper">
      <section className="content-header">
        <div className="container-fluid">
          <div className="row mb-2">
            <div className="col-sm-6">
              <h1>{formType}</h1>
            </div>
            <div className="col-sm-6">
              <ol className="breadcrumb float-sm-right">
                <li className="breadcrumb-item">
                  <a href="#">Home</a>
                </li>
                <li className="breadcrumb-item active">{formType} Data</li>
              </ol>
            </div>
          </div>
        </div>
      </section>

      <section className="content">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12">
              <div className="card">
                <DataTable
                  columns={columns}
                  data={data}
                  type={formType}
                />
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Tables;
