import React from "react";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import {
  TOASTSUCCESS,
  TOASTWARNING,
  TOASTERROR,
  TOASTTIMEOUT,
} from "../../config/constant";
import { clearMessage } from "../../redux/actions/message";

function Toastalert(message, type) {
    console.log('message',message)
    console.log('type',type)
    switch (type) {
        case TOASTSUCCESS:
          return toast.success(message, {
            position: "top-right",
            autoClose: TOASTTIMEOUT,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light"
          });
        case TOASTWARNING:
          return toast.warn(message, {
            position: "top-right",
            autoClose: TOASTTIMEOUT,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
        case TOASTERROR:
          return toast.error(message, {
            position: "top-right",
            autoClose: TOASTTIMEOUT,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
        default:
            return toast(message, {
                position: "top-right",
                autoClose: TOASTTIMEOUT,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
              });
      }
}

export default Toastalert;
