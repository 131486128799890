import axios from "axios";
import AuthHead from './auth-headers';
import { APIEndPoint } from "../config/constant";

const addProduct = (productdata) => {
  return axios.post(`${APIEndPoint}products/add`, { productdata },{headers:AuthHead.ReqHeader()});
};

const getProduct = (productID='') => {
    return axios.get(`${APIEndPoint}products/${productID}`,{headers:AuthHead.ReqHeader()});
  };

export default { addProduct,getProduct };
