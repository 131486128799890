import { Provider } from "react-redux";
import store, { persistor } from "./redux/store";
import { PersistGate } from "redux-persist/integration/react";
import AppRoute from "./router/approuter";

function App() {
    // useEffect(() => {
    //   document.body.classList.add(
    //     'hold-transition',
    //     'sidebar-mini',
    //     'layout-fixed'
    //   );
    //   // 👇️ removing classes from body element
    //   document.body.classList.remove('my-class-3');
    //   // if (document.body.classList.contains('sidebar-mini')) {
    //   //   console.log('body tag contains class');
    //   // }
    // }, []);
    return (
        <Provider store={store}>
            <PersistGate persistor={persistor}>
                <AppRoute></AppRoute>
            </PersistGate>
        </Provider>
    );
}

export default App;
