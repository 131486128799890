import React from 'react';
import * as Yup from 'yup';
import FileUpload from '../common/FileUpload';

// Define field types
const fieldTypes = {
  TEXT: 'text',
  NUMBER: 'number',
  EMAIL: 'email',
  FILE: 'file',
  SELECT: 'select',
  CHECKBOX: 'checkbox',
  RADIO: 'radio',
};

// Create a validation schema dynamically based on field definitions
const createValidationSchema = (fields) => {
  if (!Array.isArray(fields)) {
    return Yup.object().shape({});
  }

  const schema = {};
  fields.forEach(field => {
    const { name, type, validation, errorMessages } = field;
    if (validation) {
      switch (type) {
        case fieldTypes.FILE:
          schema[name] = Yup.mixed()
            .required(validation.required ? `${field.label} is required` : undefined)
            // .test('fileSize', `File size must be less than ${(validation.size || 2 * 1024 * 1024) / (1024 * 1024)}MB`, value => {
            //   if (!value) return true; // If no file is provided, skip size check
            //   return value.size <= (validation.size || 2 * 1024 * 1024);
          //  }
           //)
            // .test('fileFormat', `Unsupported Format`, value => {
            //   if (!value) return true; // If no file is provided, skip format check
            //   return (validation.formats || ['image/jpeg', 'image/png']).includes(value.type);
            // });
          break;
        case fieldTypes.NUMBER:
          schema[name] = Yup.number()
            .required(validation.required ? errorMessages?.required || `${field.label} is required` : undefined)
            .min(validation.min, errorMessages?.min || `${field.label} must be at least ${validation.min}`)
            .max(validation.max, errorMessages?.max || `${field.label} must be at most ${validation.max}`);
          break;
        case fieldTypes.EMAIL:
          schema[name] = Yup.string()
            .email(errorMessages?.pattern || 'Invalid email address')
            .required(validation.required ? errorMessages?.required || `${field.label} is required` : undefined);
          break;
        case fieldTypes.CHECKBOX:
          schema[name] = Yup.boolean()
            .required(validation.required ? errorMessages?.required || `${field.label} is required` : undefined)
            .oneOf([true], errorMessages?.oneOf || `${field.label} must be checked`);
          break;
        case fieldTypes.RADIO:
          schema[name] = Yup.string()
            .required(validation.required ? errorMessages?.required || `${field.label} is required` : undefined);
          break;
        case fieldTypes.SELECT:
          schema[name] = Yup.string()
          .required(validation.required ? `${field.label} is required` : undefined)
          .oneOf(field.options.map(option => option.value), `Invalid selection for ${field.label}`);
          break;
        default:
          schema[name] = Yup.string()
            .required(validation.required ? errorMessages?.required || `${field.label} is required` : undefined)
            .min(validation.min, errorMessages?.min || `${field.label} must be at least ${validation.min}`)
            .max(validation.max, errorMessages?.max || `${field.label} must be at most ${validation.max}`);
      }
    } else {
      schema[name] = Yup.string();
    }
  });
  return Yup.object().shape(schema);
};

// Create form fields based on field definitions
const createFormFields = (fields = [], register, errors,setValue,defaultValues) => {
  return fields.map((field) => {
    const { type, name, label, placeholder, options = [], defaultValue } = field;

    switch (type) {
      case fieldTypes.SELECT:
        return (
          <div key={name} className="form-group col-md-5">
            <label htmlFor={name}>{label}</label>
            <select
              id={name}
              className="form-control"
              defaultValue={defaultValue || options[0]?.value || ''}
              {...register(name)}
            >
              {options.length > 0 ? (
                options.map(option => (
                  <option key={option.value} value={option.value}>
                    {option.label}
                  </option>
                ))
              ) : (
                <option value="">No options available</option>
              )}
            </select>
            {errors[name] && <small className="form-text text-danger">{errors[name]?.message}</small>}
          </div>
        );
      case fieldTypes.FILE:
        return (
          <FileUpload
            key={name}
            field={field}
            register={register}
            errors={errors}
            setValue={setValue}
            existingFiles={defaultValues[name] || []} // Pass existing files 
          />
        );
      case fieldTypes.CHECKBOX:
        return (
          <div key={name} className="form-check col-md-5">
            <input
              type="checkbox"
              id={name}
              className="form-check-input"
              {...register(name)}
            />
            <label
              className="form-check-label"
              htmlFor={name}
            >
              {label}
            </label>
            {errors[name] && <small className="form-text text-danger">{errors[name]?.message}</small>}
          </div>
        );
      case fieldTypes.RADIO:
        return (
          <div key={name} className="form-group col-md-5">
            <label>{label}</label>
            {options.length > 0 && options.map(option => (
              <div className="form-check" key={option.value}>
                <input
                  type="radio"
                  name={name}
                  value={option.value}
                  className="form-check-input"
                  {...register(name)}
                  defaultChecked={defaultValue === option.value}
                />
                <label className="form-check-label">
                  {option.label}
                </label>
              </div>
            ))}
            {errors[name] && <small className="form-text text-danger">{errors[name]?.message}</small>}
          </div>
        );
      default:
        return (
          <div key={name} className="form-group col-md-5">
            <label htmlFor={name}>{label}</label>
            <input
              id={name}
              type={type}
              className="form-control"
              placeholder={placeholder || ''}
              {...register(name)}
              defaultValue={defaultValue || ''}
            />
            {errors[name] && <small className="form-text text-danger">{errors[name]?.message}</small>}
          </div>
        );
    }
  });
};

export { createValidationSchema, createFormFields, fieldTypes };
