import React from "react";
import { useTable, useBlockLayout, useResizeColumns } from 'react-table'

function Tables({ data }) {
  console.log('data',data);
  const columns = Object.keys(data[0]).map((key, id)=>{
    return {
      Header: key,
      accessor: key
    }
  })
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({
      columns,
      data,
      useBlockLayout,
      useResizeColumns
    });
 return (
    <table {...getTableProps()}>

        <thead>
            {headerGroups[1].headers.map(column => (
                <th {...column.getHeaderProps()}>{column.render('Header')}</th>
            ))}
        </thead>
        <tbody {...getTableBodyProps()}>
            {rows.map(
                (row, i) => {
                    prepareRow(row);
                    return (
                        <tr {...row.getRowProps()}>
                            {row.cells.map(cell => {
                                return <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                            })}
                        </tr>
                    )
                }
            )}
        </tbody>
    </table>
);
}

export default Tables;
