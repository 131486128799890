import { SUCCESS_CONFIGDATA,CONFIGFORM_FAILURE } from '../types';

const initialState = {
  formdata: null,
  loading: false,
  error: null, // Initialize error here
};
  export default function (state = initialState, action) {
      console.warn('action',action);
      switch (action.type) {
        case SUCCESS_CONFIGDATA:
          return {
            ...state,
            form: action.payload.data,
            loading: false,
            error: null, // Reset error on success
          };
        // case LOGIN_REQUEST:
        //   return {
        //     ...state,
        //     loading: true,  // Set loading to true on login request
        //     error: null,    // Reset error on new request
        //   };
        case CONFIGFORM_FAILURE:
          return {
            ...state,
            data: null,
            loading: false,
            error: action.payload, // Set error on failure
          };
        default:
          return state;
      }
  }