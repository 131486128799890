import React, { useState } from 'react';

const processValue = (value, onMakePrimary) => {
    // Check if the value is an array of objects
    if (Array.isArray(value)) {
      return (
        <div>
          {value.map((item, index) =>
            typeof item === 'object' && item !== null ? (
              <div key={index} style={{ marginBottom: '20px', position: 'relative' }}>
                {/* Show the main image link and thumbnail as a popup */}
                {item.mainImagelink ? (
                  <ImagePopup src={item.mainImagelink} />
                ) : (
                  'No image available'
                )}
                {/* Optionally, display other fields like mainImageName */}
                <div>
                  <strong>{item.mainImageName}</strong>
                </div>
  
                {/* Show either "Primary Image" or "Make Primary" button */}
                {item.primaryStatus === 1 ? (
                  <button style={primaryButtonStyles}>Primary Image</button>
                ) : (
                  <button
                    style={makePrimaryButtonStyles}
                    onClick={() => onMakePrimary(item.id)} // Pass only the image ID
                  >
                    Make Primary
                  </button>
                )}
              </div>
            ) : (
              item
            )
          )}
        </div>
      );
    } else if (typeof value === 'object' && value !== null) {
      // If it's a single object, process it
      return value.mainImagelink ? <ImagePopup src={value.mainImagelink} /> : JSON.stringify(value);
    }
    // For primitive values (strings, numbers), return the value directly
    return value;
  };
// Component to handle image and popup
const ImagePopup = ({ src }) => {
  const [isOpen, setIsOpen] = useState(false);

  const handleImageClick = (event) => {
    event.preventDefault();
    setIsOpen(true);
  };

  const handleClose = () => {
    setIsOpen(false);
  };

  return (
    <>
      {/* Display the image as a clickable link */}
      <a href="#" onClick={handleImageClick} style={{ display: 'block', marginBottom: '10px' }}>
        <img src={src} alt="Thumbnail" style={{ maxWidth: '150px', maxHeight: '150px' }} />
      </a>

      {/* Modal Popup for the full image */}
      {isOpen && (
        <div style={popupOverlayStyles}>
          <div style={popupStyles}>
            <span style={closeButtonStyles} onClick={handleClose}>
              &times;
            </span>
            <img src={src} alt="Full Image" style={{ maxWidth: '100%', maxHeight: '100%' }} />
          </div>
        </div>
      )}
    </>
  );
};

// Styles for buttons and popup
const primaryButtonStyles = {
  position: 'absolute',
  top: '5px',
  right: '5px',
  backgroundColor: 'green',
  color: 'white',
  border: 'none',
  padding: '5px 10px',
  cursor: 'default',
};

const makePrimaryButtonStyles = {
  position: 'absolute',
  top: '5px',
  right: '5px',
  backgroundColor: 'blue',
  color: 'white',
  border: 'none',
  padding: '5px 10px',
  cursor: 'pointer',
};

const popupOverlayStyles = {
  position: 'fixed',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  backgroundColor: 'rgba(0, 0, 0, 0.8)',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  zIndex: 1000,
};

const popupStyles = {
  position: 'relative',
  backgroundColor: '#fff',
  padding: '20px',
  borderRadius: '8px',
  maxWidth: '90%',
  maxHeight: '90%',
  textAlign: 'center',
};

const closeButtonStyles = {
  position: 'absolute',
  top: '10px',
  right: '15px',
  fontSize: '24px',
  cursor: 'pointer',
};

export default processValue;
