import {
    LOGIN_SUCCESS,
    LOGIN_FAILURE,
    LOGOUT,
    SET_MESSAGE,CLEAR_MESSAGE
  } from "../types";

import { clearMessage } from "./message";
import { setLoading } from "./loading";
import ApiService from "../../services/apiService";

// Login action
export const login = (credentials) => async (dispatch) => {
  //dispatch({ type: LOGIN_REQUEST });
  dispatch(setLoading(30));
console.log(credentials);
  try {
    const response = await ApiService.post('login', JSON.stringify(credentials));
    dispatch(setLoading(70));
    dispatch(clearMessage())
    // Assuming that the response contains a user object on successful login
    dispatch({ 
      type: LOGIN_SUCCESS, 
      payload: response 
    });
    dispatch(setLoading(100));
  } catch (error) {
    const message =
      (error.response &&
        error.response.data &&
        error.response.data.message) ||
      error.message ||
      error.toString();
    dispatch({ 
      type: LOGIN_FAILURE, 
      payload: message
    });
    dispatch(setLoading(100));
  }
};
  
export const logout = () => async (dispatch) => {
  try {
    // Clear redux state
    dispatch({ type: LOGOUT });

  } catch (error) {
    console.error('Failed to clear storage or logout', error);
  }
};
