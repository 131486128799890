import React, { useState, useEffect } from 'react';
import { useTable, usePagination, useFilters, useSortBy } from 'react-table';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import './TableStyles.css'; // Your CSS file

const DataTable = ({ columns, data, type, onStatusUpdate }) => {
  const navigate = useNavigate();
  const [searchTerm, setSearchTerm] = useState('');
  const [filteredData, setFilteredData] = useState(data);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    state: { pageIndex, pageSize },
    setFilter,
    gotoPage,
    setPageSize,
    canPreviousPage,
    canNextPage,
    pageOptions,
    previousPage,
    nextPage,
    setSortBy,
  } = useTable(
    {
      columns,
      data: filteredData,
      initialState: { pageIndex: 0, pageSize: 5 },
    },
    useFilters,
    useSortBy,
    usePagination
  );

  useEffect(() => {
    const lowercasedSearchTerm = searchTerm.toLowerCase();
    const newFilteredData = data.filter(row =>
      Object.values(row).some(value =>
        value.toString().toLowerCase().includes(lowercasedSearchTerm)
      )
    );
    setFilteredData(newFilteredData);
  }, [searchTerm, data]);

  const handleStatusChange = async (rowId, currentStatus) => {
    const newStatus = currentStatus === 'Active' ? 'InActive' : 'Active';

    try {
      await axios.post('/api/updateStatus', { id: rowId, status: newStatus });
      onStatusUpdate(rowId, newStatus);
    } catch (error) {
      console.error('Failed to update status:', error);
    }
  };

  const handleEdit = (rowId) => {
    navigate(`/add/${type}/${rowId}`);
  };

  return (
    <div className="table-container">
      <div className="table-header">
        <div className="search-container">
          <input
            type="text"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            placeholder="Search..."
          />
        </div>
      </div>
      <div className="table-body">
        <table {...getTableProps()} className="table">
          <thead>
            {headerGroups.map(headerGroup => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map(column => (
                  <th
                    {...column.getHeaderProps(column.getSortByToggleProps())}
                    onClick={() => column.canSort && setSortBy([{ id: column.id, desc: !column.isSortedDesc }])}
                  >
                    {column.render('Header')}
                    <span>
                      {column.isSorted
                        ? column.isSortedDesc
                          ? ' 🔽'
                          : ' 🔼'
                        : ''}
                    </span>
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {page.map(row => {
              prepareRow(row);
              return (
                <tr {...row.getRowProps()}>
                  {row.cells.map(cell => (
                    <td {...cell.getCellProps()}>
                      {cell.column.id === 'status' ? (
                        <button
                          className={`btn btn-sm ${row.original.status === 'Active' ? 'btn-warning' : 'btn-success'}`}
                          onClick={() => handleStatusChange(row.original.appdataid, row.original.status)}
                        >
                          {row.original.status}
                        </button>
                      ) : cell.column.id === 'edit' ? (
                        <button
                          className="btn btn-primary btn-sm"
                          onClick={() => handleEdit(row.original.appdataid)}
                        >
                          Edit
                        </button>
                      ) : (
                        cell.render('Cell')
                      )}
                    </td>
                  ))}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
      <div className="table-footer">
        <div className="row-selector">
          <select
            id="pageSize"
            className="form-control"
            value={pageSize}
            onChange={(e) => setPageSize(Number(e.target.value))}
          >
            {[5, 10, 20].map(size => (
              <option key={size} value={size}>
                {size}
              </option>
            ))}
          </select>
        </div>
        <div className="pagination-controls">
          <ul className="pagination pagination-sm m-0">
            <li className="page-item">
              <button
                className="page-link"
                onClick={() => gotoPage(0)}
                disabled={!canPreviousPage}
              >
                {'<<'}
              </button>
            </li>
            <li className="page-item">
              <button
                className="page-link"
                onClick={() => previousPage()}
                disabled={!canPreviousPage}
              >
                {'<'}
              </button>
            </li>
            <li className="page-item">
              <button
                className="page-link"
                onClick={() => nextPage()}
                disabled={!canNextPage}
              >
                {'>'}
              </button>
            </li>
            <li className="page-item">
              <button
                className="page-link"
                onClick={() => gotoPage(pageOptions.length - 1)}
                disabled={!canNextPage}
              >
                {'>>'}
              </button>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default DataTable;
